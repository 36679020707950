<template>
<div></div>
</template>

<script>


export default {
    inheritAttrs: false,
    name: "Amp",
    props: ["id"],
    created() {
        window.location.href = "https://titulos.com.ar/titulo_amp.php?id="+this.id;
    },
  
};
</script>